'use client';

import { getConfig } from '@/lib/get-config';
import { getList } from '@/requests/api/get-list';
import {
  MAX_PAGE_SIZE,
  NEVER_FETCH_NESTED_FIELDS,
  QUERY_KEYS,
} from '@/requests/constants';
import { useGetCount } from '@/requests/hooks';
import {
  aggregateNestedData,
  chunkArray,
  extractNestedIds,
  mergeDataWithNested,
} from '@/requests/hooks/utils';
import type { ListResponse, QueryParams } from '@/requests/types';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import {
  useQueries,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { customCaseHandlers } from './custom-cases';

export function useFetchAllInstances<
  TInstance extends BaseInstance,
  TransformedData = ListResponse<TInstance>,
>(
  options: Omit<
    UseQueryOptions<ListResponse<TInstance>['list']>,
    'queryKey' | 'queryFn'
  > & {
    modelName: ModelName;
    order?: QueryParams['order'];
    filters?: QueryParams['filters'];
    nested?: (keyof TInstance)[];
    slim?: boolean;
    enabled?: boolean;
    search?: string;
    fetchAllManyRelations?: boolean;
    waitForAllNested?: boolean;
    transform?: (list: ListResponse<TInstance>) => TransformedData;
  }
) {
  const {
    modelName,
    order,
    filters,
    nested = [],
    slim,
    fetchAllManyRelations = false,
    enabled,
    search,
    waitForAllNested,
    transform = (list) => list,
  } = options;

  /**
   * Fetch configuration based on the provided model name.
   */
  const config = getConfig<ModelName, TInstance>(modelName ?? null, true);

  /**
   * Filter out fields that should never be fetched to avoid unnecessary data fetching.
   */
  const filteredNested = nested.filter(
    (fieldName) => !NEVER_FETCH_NESTED_FIELDS.find((f) => f === fieldName)
  );

  const totalCountToFetch = useGetCount({
    modelName: options.modelName,
    filters: options.filters,
    enabled,
  });

  const totalSize = totalCountToFetch.data;

  const pageAmount = totalSize
    ? totalSize < 500
      ? 1
      : Math.ceil(totalSize / 500)
    : 0;

  const batchedQueries = Array.from({ length: pageAmount }).map((_, page) => {
    return {
      queryKey: [
        modelName,
        QUERY_KEYS.ALL_INSTANCES,
        filters,
        search,
        slim,
        order,
        fetchAllManyRelations,
        page + 1,
      ],
      queryFn: async () => {
        const res = await getList<TInstance>({
          modelName,
          queryParams: {
            page: page + 1,
            pageSize: MAX_PAGE_SIZE,
            filters,
            search,
            order,
            slim,
          },
        });
        return res.list;
      },
      ...options,
    };
  });

  const combine = useCallback(
    (results: UseQueryResult<TInstance[], Error>[]) => {
      const instances =
        results
          .flatMap((result) => result.data)
          .filter((res) => res !== undefined) ?? [];
      return {
        data: {
          list: instances,
          meta: {
            total_amount: instances.length,
            page: 1,
            page_amount: instances.length,
            page_size: instances.length,
          },
        },
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError),
        isFetching: results.some((result) => result.isFetching),
        isPending: results.some((result) => result.isPending),
        isRefetching: results.some((result) => result.isRefetching),
        isStale: results.every((result) => result.isStale),
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
    []
  );

  const mainQueries = useQueries({
    queries: batchedQueries,
    combine: combine,
  });

  /**
   * Extract nested IDs from the main list to identify which nested relations need to be fetched.
   */
  const nestedIds = extractNestedIds(
    mainQueries.data.list,
    filteredNested,
    fetchAllManyRelations
  );

  /**
   * Prepare the list of nested queries to be fetched based on the extracted IDs.
   */
  const nestedToFetch = Array.from(nestedIds).map(([fieldName, idsList]) => {
    const fieldConfig = config?.fields[fieldName];
    // Ensure the field is a valid relation field.
    if (!fieldConfig || !('relationConfig' in fieldConfig)) {
      throw new Error(
        `Field ${String(fieldName)} is not a relation field in model ${modelName}`
      );
    }
    const relationModelName = fieldConfig.relationConfig;
    const batchedIds = chunkArray(idsList, 40); // Chunk IDs into batches of 40 for optimized API calls.
    return {
      fieldName,
      relationModelName,
      batchedIds,
    };
  });

  /**
   * Use React Query's useQueries to fetch all nested relations in parallel.
   */
  const nestedQueries = useQueries({
    queries: nestedToFetch.flatMap(
      ({ fieldName, relationModelName, batchedIds }) => {
        const batchedQueries = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_, batch] of batchedIds.entries()) {
          const ids = batch.join(',');
          batchedQueries.push({
            queryKey: ['nested', relationModelName, ids],
            queryFn: async () => {
              const { list } = await getList<TInstance>({
                modelName: relationModelName,
                queryParams: {
                  filters: {
                    id: { __in: ids },
                  },
                  pageSize: batch.length,
                },
              });
              return { fieldName, data: list };
            },
            enabled: batch.length > 0 && mainQueries.isSuccess,
          });
        }
        return batchedQueries;
      }
    ),
  });

  /**
   * Aggregate the fetched nested data into a map for efficient access during merging.
   */
  const aggregatedNestedData = aggregateNestedData(nestedQueries);

  /**
   * Merge the aggregated nested data with the main list data.
   */
  const mergedData = useMemo(() => {
    if (!mainQueries.data || !aggregatedNestedData.size) {
      return mainQueries.data;
    }
    return mergeDataWithNested(
      mainQueries.data,
      aggregatedNestedData,
      filteredNested
    );
  }, [mainQueries.data, aggregatedNestedData, filteredNested]);

  const customizedDataResult = useQuery({
    queryKey: ['customizedData', modelName, JSON.stringify(mergedData)],
    queryFn: async () => {
      const handler = customCaseHandlers[modelName];
      if (!handler) {
        return mergedData;
      }
      try {
        const processedList = await handler({ data: mergedData?.list ?? [] });
        return {
          list: processedList.data,
          meta: mergedData?.meta,
        } as ListResponse<TInstance>;
      } catch {
        return mergedData;
      }
    },
    enabled: !!mergedData?.list.length && !!customCaseHandlers[modelName],
  });

  /**
   * Determine if there is a custom case handler for the model.
   */
  const hasCustomCaseHandler = !!customCaseHandlers[modelName];

  const transformedData = useMemo(() => {
    if (hasCustomCaseHandler && customizedDataResult.data) {
      return transform(customizedDataResult.data) as TransformedData;
    }
    return transform(mergedData) as TransformedData;
  }, [customizedDataResult.data, hasCustomCaseHandler, mergedData, transform]);

  const isNestedFetching = nestedQueries.some((q) => q.isFetching);
  const isNestedPending = nestedQueries.some((q) => q.isPending);
  const isNestedLoading = nestedQueries.some((q) => q.isLoading);
  const isNestedRefetching = nestedQueries.some((q) => q.isRefetching);
  const isNestedStale = nestedQueries.every((q) => q.isStale);
  const isNestedError = nestedQueries.some((q) => q.isError);
  const isNestedSuccess = nestedQueries.every((q) => q.isSuccess);

  /**
   * Combine the loading states based on the `waitForAllNested` flag.
   */
  const isLoading = waitForAllNested
    ? mainQueries.isLoading || isNestedLoading || customizedDataResult.isLoading
    : mainQueries.isLoading;
  const isFetching = waitForAllNested
    ? mainQueries.isFetching ||
      isNestedFetching ||
      customizedDataResult.isFetching
    : mainQueries.isFetching;
  const isPending = waitForAllNested
    ? mainQueries.isPending || isNestedPending || customizedDataResult.isPending
    : mainQueries.isPending;
  const isAllSuccess = waitForAllNested
    ? mainQueries.isSuccess || isNestedSuccess || customizedDataResult.isSuccess
    : mainQueries.isSuccess;

  return {
    data: mergedData,
    transformedData,
    isLoading,
    isFetching,
    isPending,
    isSuccess: mainQueries.isSuccess,
    isAllSuccess,
    isError: isNestedError || mainQueries.isError || totalCountToFetch.isError,
    isRefetching:
      isNestedRefetching ||
      mainQueries.isRefetching ||
      totalCountToFetch.isRefetching,
    isStale: isNestedStale && mainQueries.isStale && totalCountToFetch.isStale,
  };
}
