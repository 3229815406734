import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  GeneralErrandDefaultDescriptionConfig,
  type GeneralErrandDefaultDescription,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const GeneralErrandDefaultDescriptionItem: RelationFieldProps<GeneralErrandDefaultDescription> =
  {
    nested: getRelationFieldNames(GeneralErrandDefaultDescriptionConfig),
    renderItem: (item) => {
      return {
        title: item.title ?? '',
        descriptionItems: (
          <div className='w-full font-medium'>
            <strong>Kod:</strong> {item.code}
          </div>
        ),
      };
    },
  };
