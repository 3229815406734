import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  GeneralErrandActionConfig,
  type GeneralErrandAction,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';
export const GeneralErrandActionItem: RelationFieldProps<GeneralErrandAction> =
  {
    nested: getRelationFieldNames(GeneralErrandActionConfig),
    filters: [],
    renderItem: (item, isRelationField) => ({
      title: item?.title as string,
      descriptionItems: (
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span className='font-medium'>{item?.errand?.title}</span>
        </div>
      ),
    }),
  };
