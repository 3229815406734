import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ErrandActionDefaultConfig,
  type ErrandActionDefault,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const ErrandActionDefaultConfigItem: RelationFieldProps<ErrandActionDefault> =
  {
    nested: getRelationFieldNames(ErrandActionDefaultConfig),
    renderItem: (item) => {
      return {
        title: item.title ?? '',
        descriptionItems: (
          <div className='w-full font-medium'>
            <strong>Kod:</strong> {item.code}
          </div>
        ),
      };
    },
  };
