'use client';
import { ComponentConditionNotationValueBadge } from '@/app/detail/components/errand-components/condition/badge';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ComponentConditionValueConfig,
  type ComponentConditionNotation,
  type ComponentConditionValue,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';

export const ComponentConditionValueItem: RelationFieldProps<ComponentConditionValue> =
  {
    nested: getRelationFieldNames(ComponentConditionValueConfig),
    filters: [],

    renderItem: (item) => ({
      title: (
        <>
          <ComponentConditionNotationValueBadge
            componentConditionNotation={
              { value: item } as ComponentConditionNotation
            }
          />
        </>
      ),
      descriptionItems: <></>,
    }),
  };
