import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ComponentTypeConfig,
  type ComponentType,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';
export const ComponentTypeItem: RelationFieldProps<ComponentType> = {
  nested: getRelationFieldNames(ComponentTypeConfig),
  filters: [],
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {item?.includeInInspections
            ? 'Inkluderas i besiktningar'
            : 'Exluderas från besiktningar'}
        </span>
      </div>
    ),
  }),
};
