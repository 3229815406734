import { DynamicImage } from '@/app/detail/generalerrand/components/detail-image-gallery';
import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { RoomCategoryConfig, type RoomCategory } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './types';
export const RoomCategoryItem: RelationFieldProps<RoomCategory> = {
  nested: getRelationFieldNames(RoomCategoryConfig),
  filters: [],
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          <div className='flex items-center gap-2'>
            <DynamicImage
              src={item.icon?.get ?? ''}
              alt={item.name}
              width={20}
              height={20}
            />
          </div>
        </span>
      </div>
    ),
  }),
};
