import { cn } from '@/lib/cn';
import { PencilIcon } from '@heroicons/react/16/solid';
import type { ComponentConditionNotation } from '@pigello/pigello-matrix';

export const ComponentConditionNotationValueBadge = ({
  componentConditionNotation,
  withEditIcon,
}: {
  componentConditionNotation?: ComponentConditionNotation;
  withEditIcon?: boolean;
}) => {
  if (componentConditionNotation?.value == null) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex size-fit items-center gap-2 rounded-xl border px-2 py-1 text-xs font-light'
      )}
    >
      <div
        className={`size-2 min-h-2 min-w-2 rounded-full`}
        style={{
          backgroundColor: `#${componentConditionNotation.value.colorCode}`,
        }}
      />
      {componentConditionNotation.value.name}{' '}
      {withEditIcon && <PencilIcon className='size-3' />}
    </div>
  );
};
