import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ExternalSupplierConfig,
  type ExternalSupplier,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';
export const ExternalSupplierItem: RelationFieldProps<ExternalSupplier> = {
  nested: getRelationFieldNames(ExternalSupplierConfig),
  filters: [],
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>{item?.orgNo}</span>
        <Dot />
        <span className='font-medium'>{item?.errandsEmail}</span>
      </div>
    ),
  }),
};
