import { type BaseInstance, type ModelName } from '@pigello/pigello-matrix';
import { AddressItem } from './address-item';
import { ApartmentItem } from './apartment';
import { ApartmentContractItem } from './apartment-contract';
import { ApartmentVacancyItem } from './apartment-vacany-item';
import { ArticleItem } from './article';
import { ArticleCategoryItem } from './article-category-item';
import { AutomaticContractInvoicingSettingItem } from './automatic-contract-invoicing-setting';
import { BlockContractItem } from './block-contract-item';
import { BrfApartmentInvoicingConfigurationItem } from './brf-apartment-invoicing-configuration';
import { BrfApartmentItem } from './brf-apartment-item';
import { BrfCompanyItem } from './brf-company';
import { BuildingItem } from './building';
import { CancellationReasonItem } from './cancellation-reason';
import { CommonAreaItem } from './common-area';
import { CommunityItem } from './community';
import { CompanyItem } from './company';
import { ComponentConditionValueItem } from './component-condition-value';
import { ComponentModelItem } from './componentmodel';
import { ComponentTypeItem } from './componenttype';
import { CustomerEarningsSettingsItem } from './customer-earnings-setting';
import { DebtInvoiceItem } from './debtinvoice';
import { ErrandActionDefaultConfigItem } from './errand-action-default-item';
import { GeneralErrandActionItem } from './errandaction';
import { ExternalClientItem } from './externalclient';
import { ExternalSupplierItem } from './externalsupplier';
import { GeneralErrandDefaultDescriptionItem } from './general-errand-default-description-item';
import { IndexationSettingItem } from './indexation-settings';
import { IndexationTableItem } from './indexation-table';
import { IndustrialPremisesItem } from './industrial-premises';
import { IndustrialPremisesContractItem } from './industrial-premises-contract';
import { IndustrialPremisesVacancyItem } from './industrialpremises-vacany-item';
import { InvoiceItem } from './invoice';
import { PaymentTermsSettingItem } from './invoicing-paymentterms-setting-item';
import { OrganizationUserItem } from './organization-user';
import { OtherContractItem } from './other-contract';
import { OutdoorAreaItem } from './outdoor-area';
import { OutdoorSectionItem } from './outdoor-section';
import { OutdoorSectionContractItem } from './outdoor-section-contract';
import { OutdoorSectionVacancyItem } from './outdoorsections-vacany-item';
import { ParkingLotItem } from './parking-lot';
import { ParkingSpotItem } from './parking-spot';
import { ParkingSpotContractItem } from './parking-spot-contract';
import { ParkingSpotVacancyItem } from './parkingspot-vacany-item';
import { PaymentMethodItem } from './paymentmethod';
import { QuotaCollectionItem } from './quota-collection';
import { QuotaFeeItem } from './quotafee-item';
import { RealestateItem } from './real-estate';
import { RegionItem } from './region';
import { RevenueRowItem } from './revenue-row-item';
import { RoleItem } from './role';
import { RoomCategoryItem } from './roomcategory';
import { SegmentItem } from './segment';
import { SensorSpecificationItem } from './sensor-specification';
import { SignableDocumentItem } from './signable-document';
import { TagItem } from './tag';
import { TaxationRecordItem } from './taxation-record';
import { TenantItem } from './tenant';
import type { RelationFieldProps } from './types';
import { VacancyInvoiceItem } from './vacancy-invoice-item';
import { WorkEngagementItem } from './workengagement';

export function getSelectItemFromModelName<T extends BaseInstance>(
  modelName: ModelName
): RelationFieldProps<T> {
  const map: Partial<Record<ModelName, RelationFieldProps<T>>> = {
    article: ArticleItem as unknown as RelationFieldProps<T>,
    apartmentvacancynotation:
      ApartmentVacancyItem as unknown as RelationFieldProps<T>,
    industrialpremisesvacancynotation:
      IndustrialPremisesVacancyItem as unknown as RelationFieldProps<T>,
    outdoorsectionvacancynotation:
      OutdoorSectionVacancyItem as unknown as RelationFieldProps<T>,
    parkingspotvacancynotation:
      ParkingSpotVacancyItem as unknown as RelationFieldProps<T>,
    building: BuildingItem as unknown as RelationFieldProps<T>,
    blockcontract: BlockContractItem as unknown as RelationFieldProps<T>,
    brfapartment: BrfApartmentItem as unknown as RelationFieldProps<T>,
    outdoorarea: OutdoorAreaItem as unknown as RelationFieldProps<T>,
    parkinglot: ParkingLotItem as unknown as RelationFieldProps<T>,
    apartment: ApartmentItem as unknown as RelationFieldProps<T>,
    industrialpremises:
      IndustrialPremisesItem as unknown as RelationFieldProps<T>,
    parkingspot: ParkingSpotItem as unknown as RelationFieldProps<T>,
    outdoorsection: OutdoorSectionItem as unknown as RelationFieldProps<T>,
    commonarea: CommonAreaItem as unknown as RelationFieldProps<T>,
    region: RegionItem as unknown as RelationFieldProps<T>,
    realestate: RealestateItem as unknown as RelationFieldProps<T>,
    community: CommunityItem as unknown as RelationFieldProps<T>,
    company: CompanyItem as unknown as RelationFieldProps<T>,
    tenant: TenantItem as unknown as RelationFieldProps<T>,
    apartmentcontract:
      ApartmentContractItem as unknown as RelationFieldProps<T>,
    outdoorsectioncontract:
      OutdoorSectionContractItem as unknown as RelationFieldProps<T>,
    parkingspotcontract:
      ParkingSpotContractItem as unknown as RelationFieldProps<T>,
    industrialpremisescontract:
      IndustrialPremisesContractItem as unknown as RelationFieldProps<T>,
    organizationuser: OrganizationUserItem as unknown as RelationFieldProps<T>,
    contractcancellationreason:
      CancellationReasonItem as unknown as RelationFieldProps<T>,
    sensorspecification:
      SensorSpecificationItem as unknown as RelationFieldProps<T>,
    othercontract: OtherContractItem as unknown as RelationFieldProps<T>,
    workengagement: WorkEngagementItem as unknown as RelationFieldProps<T>,
    signabledocument: SignableDocumentItem as unknown as RelationFieldProps<T>,
    tag: TagItem as unknown as RelationFieldProps<T>,
    indexationtable: IndexationTableItem as unknown as RelationFieldProps<T>,
    role: RoleItem as unknown as RelationFieldProps<T>,
    segment: SegmentItem as unknown as RelationFieldProps<T>,
    invoice: InvoiceItem as unknown as RelationFieldProps<T>,
    paymentmethod: PaymentMethodItem as unknown as RelationFieldProps<T>,
    articlecategory: ArticleCategoryItem as unknown as RelationFieldProps<T>,
    quotacollection: QuotaCollectionItem as unknown as RelationFieldProps<T>,
    brfcompany: BrfCompanyItem as unknown as RelationFieldProps<T>,
    taxationrecord: TaxationRecordItem as unknown as RelationFieldProps<T>,
    apartmentrevenuerow: RevenueRowItem as unknown as RelationFieldProps<T>,
    quotafee: QuotaFeeItem as unknown as RelationFieldProps<T>,
    indexationsetting:
      IndexationSettingItem as unknown as RelationFieldProps<T>,
    automaticcontractinvoicingsetting:
      AutomaticContractInvoicingSettingItem as unknown as RelationFieldProps<T>,
    paymenttermssetting:
      PaymentTermsSettingItem as unknown as RelationFieldProps<T>,
    brfapartmentinvoiceconfiguration:
      BrfApartmentInvoicingConfigurationItem as unknown as RelationFieldProps<T>,
    externalclient: ExternalClientItem as unknown as RelationFieldProps<T>,
    debtinvoice: DebtInvoiceItem as unknown as RelationFieldProps<T>,
    customerearningsrevenuesetting:
      CustomerEarningsSettingsItem as unknown as RelationFieldProps<T>,
    address: AddressItem as unknown as RelationFieldProps<T>,
    vacancyinvoice: VacancyInvoiceItem as unknown as RelationFieldProps<T>,
    generalerranddefaultdescription:
      GeneralErrandDefaultDescriptionItem as unknown as RelationFieldProps<T>,
    errandactiondefault:
      ErrandActionDefaultConfigItem as unknown as RelationFieldProps<T>,
    componentconditionvalue:
      ComponentConditionValueItem as unknown as RelationFieldProps<T>,
    roomcategory: RoomCategoryItem as unknown as RelationFieldProps<T>,
    externalsupplier: ExternalSupplierItem as unknown as RelationFieldProps<T>,
    componenttype: ComponentTypeItem as unknown as RelationFieldProps<T>,
    componentmodel: ComponentModelItem as unknown as RelationFieldProps<T>,
    generalerrandaction:
      GeneralErrandActionItem as unknown as RelationFieldProps<T>,
  };
  return (
    map[modelName] ?? {
      filters: [],
      renderItem: () => ({
        title: 'Not implemented',
        badge: undefined,
        descriptionItems: <>{modelName}</>,
      }),
    }
  );
}
