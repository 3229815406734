import { Button } from '@/components/ui/button';
import { DialogHeader } from '@/components/ui/detail-dialog';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/cn';
import { getHeadersForDynamicBE } from '@/requests/api/utils';
import { BASE_DYNAMIC_BACKEND_PUBLIC_FILE_URL } from '@/requests/constants';
import { useFetchAllInstances } from '@/requests/hooks/use-fetch-all-instances';
import {
  ArrowDownOnSquareIcon,
  DocumentTextIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import type { GeneralErrandFile, ModelName } from '@pigello/pigello-matrix';
import {
  type GeneralErrandAction,
  type GeneralErrandActionFile,
} from '@pigello/pigello-matrix';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import {
  forwardRef,
  Fragment,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';

const container = {
  hidden: { opacity: 0.5 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: -5 },
  show: { opacity: 1, y: 0 },
};

export function DetailErrandImageGallery({
  id,
  isInbox,
}: {
  id: string;
  isInbox?: boolean;
}) {
  const { data: generalErrandActions, isPending } =
    useFetchAllInstances<GeneralErrandAction>({
      modelName: 'generalerrandaction',
      order: ['-createdAt'],
      filters: {
        errand: {
          noop: id,
        },
      },
      enabled: !!id,
    });
  // const { data: generalErrandFiles } = useFetchAllIds<GeneralErrandFile>({
  //   idAttr: 'errand',
  //   ids: [id],
  //   modelName: 'generalerrandfile',
  // });

  return (
    <>
      <div className='flex flex-col pb-2'>
        <div
          className={cn(
            'flex items-center justify-between border-y px-4 py-2',
            { 'px-0 border-t-0': isInbox }
          )}
        >
          <motion.h4 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            Bilder och media
          </motion.h4>
        </div>
        {isPending && (
          <div className='grid grid-cols-2 gap-4 p-4'>
            <DetailErrandImageSkeleton />
          </div>
        )}
        <div
          className={cn(
            'flex h-full flex-col gap-2 overflow-y-auto px-4 pt-2',
            { 'px-0': isInbox }
          )}
        >
          <motion.div
            className={cn('grid grid-cols-3 items-stretch gap-4', {
              'grid-cols-5': isInbox,
            })}
            variants={container}
            initial='hidden'
            animate='show'
          >
            {generalErrandActions.list?.map((generalErrandAction, idx) => (
              <Fragment key={generalErrandAction.id}>
                <GeneralFiles
                  id={generalErrandAction.id}
                  index={generalErrandActions.list.length - idx}
                  filterAttr='errand_action'
                  modelName='generalerrandactionfile'
                />
              </Fragment>
            ))}
            <>
              <GeneralFiles
                id={id}
                index={0}
                filterAttr='errand'
                modelName='generalerrandfile'
              />
            </>
          </motion.div>
        </div>
      </div>
    </>
  );
}

type ErrandFileType = GeneralErrandActionFile & GeneralErrandFile;

function GeneralFiles({
  id,
  filterAttr,
  modelName,
  index,
}: {
  id: string;
  filterAttr: string;
  modelName: ModelName;
  index: number;
}) {
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState<{
    src: string;
    type: MediaType;
    alt?: string;
  }>();
  const { data: files, isPending: isLoadingFiles } =
    useFetchAllInstances<ErrandFileType>({
      modelName,
      filters: {
        [filterAttr]: {
          noop: id,
        },
      },
    });

  if (!isLoadingFiles && !files.list.length) {
    return null;
  }
  return (
    <>
      {media && <ImageDialog open={open} setOpen={setOpen} media={media} />}
      <h3 className='col-span-full'>
        {index === 0 ? 'Från rapportör' : `Åtgärd ${index}`}
      </h3>
      {files.list.map((file) => {
        if (!file.fileObj) {
          return null;
        }
        const type = detectMediaTypeFromExtension(file.fileObj.get);
        return (
          <motion.div
            variants={item}
            key={file.id}
            className='group relative max-h-16 min-h-16 min-w-16'
          >
            {type !== 'other' && (
              <button
                onClick={() => {
                  if (file.fileObj) {
                    setMedia({
                      src: file.fileObj.get,
                      type,
                      alt: file.fileObj.name,
                    });
                    setOpen(true);
                  }
                }}
                className='size-full'
              >
                {type === 'image' && (
                  <DynamicImage
                    src={file.fileObj.get}
                    className='rounded-lg object-contain'
                  />
                )}
                {type === 'video' && (
                  <DynamicVideo
                    src={file.fileObj.get}
                    className='rounded-lg object-contain'
                  />
                )}
              </button>
            )}
            {type === 'other' && (
              <div className='group relative flex size-full items-center justify-center'>
                <DocumentTextIcon className='size-8' />
                <div className='invisible absolute inset-0 flex items-center justify-center rounded-md bg-black/80 group-hover:visible'>
                  <Button variant={'secondary'} size={'icon-sm'}>
                    <ArrowDownOnSquareIcon className='size-5' />
                  </Button>
                </div>
              </div>
            )}
            <Button
              variant={'secondary'}
              size='icon'
              className='absolute -right-2 -top-2 rounded-full opacity-0 transition-opacity group-hover:opacity-100'
              // onClick={() => onYeet(file.id)}
            >
              <XMarkIcon className='size-3' />
            </Button>
          </motion.div>
        );
      })}
    </>
  );
}

function DetailErrandImageSkeleton() {
  return (
    <>
      <Skeleton className='size-24 rounded-lg' />
      <Skeleton className='size-24 rounded-lg' />
      <Skeleton className='size-24 rounded-lg' />
      <Skeleton className='size-24 rounded-lg' />
      <Skeleton className='size-24 rounded-lg' />
    </>
  );
}

interface Props extends React.HTMLAttributes<HTMLImageElement> {
  src: string | undefined;
  width?: number | string;
  height?: number | string;
  alt?: string;
  size?: 'icon';
}

export const DynamicImage = forwardRef<HTMLImageElement, Props>(
  ({ src, alt, ...props }, ref) => {
    const { data: imgSrc } = useQuery({
      queryKey: ['image', src],
      queryFn: async () => {
        const fileUrl = `${BASE_DYNAMIC_BACKEND_PUBLIC_FILE_URL}/?${src}`;
        const headers = await getHeadersForDynamicBE();
        const res = await fetch(fileUrl, {
          headers,
        });
        const blob = await res.blob();
        return URL.createObjectURL(blob);
      },
      enabled: !!BASE_DYNAMIC_BACKEND_PUBLIC_FILE_URL && !!src,
    });

    if (!src && !imgSrc) {
      return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src='/assets/img/default-fallback-image.png'
          ref={ref}
          alt={alt}
          {...props}
        />
      );
    }

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={imgSrc} ref={ref} alt={alt} {...props} />;
  }
);
DynamicImage.displayName = 'DynamicImage';

interface VideoProps extends React.HTMLAttributes<HTMLVideoElement> {
  src: string | undefined;
  controls?: boolean;
}

export const DynamicVideo = forwardRef<HTMLVideoElement, VideoProps>(
  ({ src, ...props }, ref) => {
    const { data: imgSrc } = useQuery({
      queryKey: ['image', src],
      queryFn: async () => {
        const fileUrl = `${BASE_DYNAMIC_BACKEND_PUBLIC_FILE_URL}/?${src}`;
        const headers = await getHeadersForDynamicBE();
        const res = await fetch(fileUrl, {
          headers,
        });
        const blob = await res.blob();
        return URL.createObjectURL(blob);
      },
      enabled: !!BASE_DYNAMIC_BACKEND_PUBLIC_FILE_URL && !!src,
    });

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video ref={ref} preload='metadata' className='rounded-lg' {...props}>
        <source src={imgSrc} type='video/mp4' />
      </video>
    );
  }
);

DynamicVideo.displayName = 'DynamicVideo';

function ImageDialog({
  open,
  media,
  setOpen,
}: {
  open: boolean;
  media: { src: string; alt?: string; type: string };
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='p-0' size='lg'>
        <DialogHeader className='flex flex-row items-center justify-between p-4'>
          <DialogTitle>Media</DialogTitle>
          <Button
            onClick={() => setOpen(false)}
            variant='secondary'
            size='icon'
          >
            <XMarkIcon className='size-4' />
            <span className='sr-only'>Close</span>
          </Button>
        </DialogHeader>
        <div className='relative h-[600px] bg-gray-100'>
          {media.type === 'image' ? (
            // eslint-disable-next-line @next/next/no-img-element
            <DynamicImage
              src={media.src || '/placeholder.svg'}
              alt={media.alt}
              className='size-full object-contain'
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <DynamicVideo
              src={media.src}
              controls={true}
              className='size-full object-contain'
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

type MediaType = 'image' | 'video' | 'other';
const mediaExtensions = new Map<string, MediaType>([
  ['jpg', 'image'],
  ['jpeg', 'image'],
  ['png', 'image'],
  ['gif', 'image'],
  ['webp', 'image'],
  ['avif', 'image'],
  ['mp4', 'video'],
  ['webm', 'video'],
  ['mov', 'video'],
  ['avi', 'video'],
]);

function detectMediaTypeFromExtension(url: string): MediaType {
  try {
    const extension = new URL(url).pathname.split('.').pop()?.toLowerCase();
    return extension ? (mediaExtensions.get(extension) ?? 'other') : 'other';
  } catch {
    return 'other';
  }
}
